import { CheckOutlined } from "@ant-design/icons";
import clsx from "clsx";
import React, { ChangeEvent, useState } from "react";
import { useAppSelector } from "../../hooks/hook";
import { hostBE } from "../../types/host";

type inputProps = {
  nameEn: string;
  checkedList: number[];
  idChecked: number;
  ischecked: boolean;
  descriptionEn: string;
  descriptionVi: string;
  idInput: string;
  handleChange: (id: number) => void;
  value: any;
  name: string;
  lable?: string;
  reveres?: boolean;
};

function Checkbox({
  nameEn,
  checkedList,
  idChecked,
  ischecked,
  descriptionEn,
  descriptionVi,
  idInput,
  value,
  handleChange,
  lable,
  name,
  reveres,
}: inputProps) {
  const ChangesInput = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    handleChange(id);
  };
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  return (
    <div
      className={clsx(
        "w-full flex items-center sm-480:mb-5 xl:mb-9 mb-9",
        reveres && "flex-row-reverse"
      )}
    >
      <input
        checked={ischecked}
        id={idInput}
        name={name}
        type="checkbox"
        onChange={(event) => ChangesInput(event, idChecked)}
        value={value}
        className="mr-3 hidden"
      />
      <label htmlFor={idInput} className="checkbox-item cursor-pointer">
        <span>
          <img
            className="w-[11px] h-2"
            src={`${hostBE}/fe-images/tich.png`}
            alt="tick"
          />
        </span>
        {/* <CheckOutlined /> */}
      </label>
      {lable && (
        <label
          htmlFor={idInput}
          className={clsx(
            "font-normal mr-1 sm-480:text-base text-sm w-[85%] sm-480:w-auto m992:w-[75%] cursor-pointer text-black",
            reveres ? "mr-3" : "ml-4"
          )}
        >
          {isEnglish ? lable : nameEn}
        </label>
      )}
      {descriptionEn &&
        descriptionEn != "" &&
        descriptionVi &&
        descriptionVi != "" && (
          <div className="cursor-pointer relative productTooltip">
            <img src={`${hostBE}/fe-images/iconTootip.png`} alt="" className="mr-5" />
            <div className="tooltipLable m992:absolute sm-480:fixed sm-480:top-[50%] absolute top-[150%] left-[-500%] sm-480:left-0 border border-solid border-bg-gray bg-light-blue rounded-xl sm-480:min-h-[250px]   w-1920:w-[1170px] 2xl:w-[1020px] xl:w-[800px] lg:w-[620px] m992:w-[600px] m992:top-[-300%] sm:w-[550px] sm-480:w-[400px] 2xl:left-[350%] lg:left-[400%] m992:left-[400%]  w-[300px] min-h-[150px]  py-8 px-14 mb-3">
              <p className="text-black font-normal sm-480:text-base text-sm">
                {isEnglish ? descriptionVi : descriptionEn}
              </p>
            </div>
          </div>
        )}
    </div>
  );
}

export default Checkbox;
