import clsx from "clsx"
import useInView from "../../hooks/useInView"
import { hostBE } from "../../types/host"

export default function Banner () {
const {isInView, ref} = useInView()
    return <div ref={ref} className="w-full overflow-hidden h-full flex justify-center  items-center banner_home_primary">
        <img src={`${hostBE}/fe-images/intro_banner.png`} style={{    objectFit: "cover"}} alt="" className={clsx("w-full banner_home_primary_images ", {"animate__animated  animate__fadeInDown":isInView} )} />
        {/* <img src={`${hostBE}/fe-images/logo2.png`} alt="" className={clsx("sc>768:w-[70%] ", {"animate__animated  animate__fadeInDown":isInView} )} /> */}
    </div>
}