import { ChangeEvent } from "react";
import Button from "../../components/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/hook"
import { showModal } from "../../reducers/modal";
import { ROLE_ADMIN } from "../../types";
import { hostBE } from "../../types/host";
import ModalCreateUser from "./ModalCreateUser";

type Props = {
  searchUser: string | undefined
  setSearchUser: (string:any) => void
}

export default function UserSearch({searchUser, setSearchUser}:Props) {
    const dispatch = useAppDispatch();
    const userData = useAppSelector(state => state.userSlice.currentUser);

    const showModalCreateUser = ()=> {
      dispatch(showModal(<ModalCreateUser />));
    }

    const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
      setSearchUser(event.currentTarget.value);
    }


    return (
        <div className="w-full flex items-center sc>768:flex-col">
          <div className="flex-1 flex items-center border-[1px] border-solid border-border-color min-h-[50px] sc>768:w-full rounded-md overflow-hidden  bg-white focus-within:border-primary">
            <input value={searchUser} onChange={handleChange} className="h-full flex-1 pl-1 outline-none sc>768:order-2" />
            <span className="px-[15px] sc>768:order-1"><img src={`${hostBE}/fe-images/VectorSearch.png`} alt="" /></span>
          </div>
        </div>
    )
} 