import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getDate from "../../common/getDate";
import TitlePage from "../../components/ManagerComponent/TitlePage";
import { useAppSelector } from "../../hooks/hook";
import { hostBE } from "../../types/host";

function AccountInfoamation() {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userSlice);
  const { t, i18n } = useTranslation();
  const fowordHandle = () => {
    navigate("/quanly/thongtintaikhoan/edit");
  };
  return (
    <div className="w-full mb-[60px]">
      <TitlePage content="titleManager.accountTitle" />

      <div className="w-full">
        <div className="flex items-center mb-16">
          <div className="md:w-[140px]  md:h-[140px] sm-390:w-[100px] sm-390:h-[100px] w-[60px] h-[60px] rounded-[50%] relative flex items-center justify-center">
            <img
              className="rounded-[50%] w-full h-full"
              src={`${hostBE}/fe-images/loginImage.png`}
              alt="accountImage"
            />
            <img
              className="rounded-[50%] absolute top-auto translate-y-[0%] w-[85%] h-[85%] object-cover"
              src={user.currentUser?.avatarUrl ?? `${hostBE}/fe-images/logotitle.png`}
              alt="avataracount"
            />
          </div>
          <div className="h-full flex-col flex sm-390:ml-[30px] ml-6">
            <p className="sm-480:text-px20 sm-390:text-xl text-[16px] font-bold sm-390:mb-5">
              {user.currentUser?.fullname ?? t("dashboard.user.list.no_update")}
            </p>
            <p className="sm-390:text-base text-px14 font-normal sm-390:mb-5">
              {user.currentUser?.position ?? t("dashboard.user.list.no_update")}
            </p>
          </div>
        </div>

        <div className="w-full">
          <table className="w-full xl:table hidden">
            <thead>
              <tr className="lssm:text-px14 md:text-px20 font-bold text-primary py-4 text-left">
                <td className="w-[40%] py-4"> {t("form.name")}</td>
                <td className="w-[35%]  py-4">{t("career.form.gender")}</td>
                <td className="w-[25%]  py-4">{t("form.dateofbirth")}</td>
              </tr>
            </thead>
            <tbody>
              <tr className="text-black lssm:text-px14 md:text-[16px] border-b border-b-border-gray">
                <td className=" py-4">{user.currentUser?.fullname}</td>
                <td className=" py-4">
                  {user.currentUser?.gender === true
                    ? t("form.gender.male")
                    : t("form.gender.female")}
                </td>
                <td className=" py-4">
                  {user.currentUser?.birth
                    ? getDate(parseInt(user.currentUser?.birth + ""))
                    : t("dashboard.user.list.no_update")}
                </td>
              </tr>

              <tr className="sm-480:text-lg lssm:text-px14 md:text-px20 font-bold text-primary">
                <td className=" py-4">{t("career.form.email")}</td>
                <td className=" py-4"> {t("contact.form.phone")}</td>
                <td className=" py-4">{t("form.position")}</td>
              </tr>
              <tr className="lssm:text-px14 md:text-[16px] font-medium text-black border-b border-b-border-gray">
                <td className=" py-4">
                  {user.currentUser?.email ??
                    t("dashboard.user.list.no_update")}
                </td>
                <td className=" py-4">
                  {user.currentUser?.phone ??
                    t("dashboard.user.list.no_update")}
                </td>
                <td className=" py-4">
                  {user.currentUser?.position ??
                    t("dashboard.user.list.no_update")}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="sm-480:w-full w-[390px] xl:hidden table pr-[25px] overflow-hidden">
            <thead>
              <tr className="lssm:text-px14 md:text-px20 font-bold text-primary text-left">
                <td className="w-[55%] py-3">{t("form.name")}</td>
                <td className="w-[45%] py-3">{t("career.form.gender")}</td>
              </tr>
            </thead>
            <tbody>
              <tr className="text-black  lssm:text-px14 md:text-[16px] mb-2 bg-bg-table">
                <td className=" py-2">{user.currentUser?.fullname}</td>
                <td className=" py-2">
                  {user.currentUser?.gender === true
                    ? t("form.gender.male")
                    : t("form.gender.female")}
                </td>
              </tr>

              <tr className="lssm:text-px14 md:text-px20 font-bold text-primary">
                <td className="py-3">{t("form.dateofbirth")}</td>
                <td className=" py-3">{t("career.form.email")}</td>
              </tr>
              <tr className="text-black lssm:text-px14 md:text-[16px]  mb-2 bg-bg-table">
                <td className=" py-2">
                  {user.currentUser?.birth
                    ? getDate(parseInt(user.currentUser?.birth + ""))
                    : t("dashboard.user.list.no_update")}
                </td>
                <td className=" py-2">{user.currentUser?.email}</td>
              </tr>
              <tr className="lssm:text-px14 md:text-px20 font-bold text-primary">
                <td className=" py-3">{t("contact.form.phone")}</td>
                <td className=" py-3">{t("form.position")}</td>
              </tr>
              <tr className="text-black  lssm:text-px14 md:text-[16px] bg-bg-table">
                <td className=" py-2">
                  {user.currentUser?.phone ??
                    t("dashboard.user.list.no_update")}
                </td>
                <td className=" py-2">
                  {user.currentUser?.position ??
                    t("dashboard.user.list.no_update")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex md:justify-end justify-center lg:mt-[150px] mt-[60px] m992:mb-[60px] lg:mb-0">
          <button
            onClick={fowordHandle}
            className="py-[13px] lg:px-6 px-3 font-medium border rounded-md bg-primary text-white sm-390:text-base text-sm md:text-[16px]  text-center"
          >
            {t("button.btnAccountChange")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AccountInfoamation;
