import { t } from "i18next";
import React, { ChangeEvent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import resizeFile from "../../common/reSize";
import { useAppDispatch } from "../../hooks/hook";
import { putHistory } from "../../reducers/history";
import { pushPopup } from "../../reducers/popupSlice";
import historyService from "../../services/history";
import uploadService from "../../services/uploadImage";
import { HistoryState } from "../../types";
import { hostBE } from "../../types/host";

type Props = {
  lable: string;
  description: string;
  titleUpload: string;
  historyData: HistoryState;
  isView: boolean;
  setFileYear?: (File: File) => void;
  setHistoryData?: (data: HistoryState) => void | undefined;
  setSubmit?: (data: boolean) => void;
  isEnglish: boolean;
  refChi?: any;
};
function History({
  lable,
  description,
  titleUpload,
  historyData,
  isView,
  setFileYear,
  setHistoryData,
  isEnglish,
  setSubmit,
  refChi,
}: Props) {
  const [messageYear, setMessageYear] = useState("");
  const dispatch = useAppDispatch();

  const changeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];
    if (file.size < 26675200) {
      const newFile: any = await resizeFile(file, { width: 315, height: 210 });
      setFileYear?.(newFile);
    } else {
      dispatch(
        pushPopup({
          message: t("notify.min_image", {size: "25MB"}),
          type: "WARNING",
        })
      );
    }
  };
  const { t } = useTranslation();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.match(/^[0-9]{0,}$/)) {
      setHistoryData?.({
        ...historyData,
        [event.currentTarget.name]: event.currentTarget.value,
      });
      setMessageYear("");
    } else {
      setMessageYear(isEnglish ? "Vui lòng nhập số" : "Please enter the number");
    }
  };

  const HandleCheckYear = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (!isView) {
      historyService.checkYear(parseInt(value)).then((data) => {
        if (!data) {
          setMessageYear(isEnglish ? `Năm ${value} đã tồn tại` : `Year ${value} already exists`);
          setSubmit?.(false);
        } else {
          setMessageYear("");
          setSubmit?.(true);
        }
      });
    }
  };

  const handleChangeText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    if (value.length <= 1000) {
      setHistoryData?.({
        ...historyData,
        [event.currentTarget.name]: event.currentTarget.value,
      });
    }
  };

  return (
    <div
      className="w-full h-auto flex flex-wrap justify-between mb-[50px]"
      ref={refChi}
    >
      <div className="w-full md:w-[32%] h-full">
        <label
          htmlFor=""
          className="text-primary lssm:text-px14 md:text-px20 font-bold"
        >
          { isEnglish ? "Year" : "Năm" }
        </label>
        <input
          type="text"
          value={historyData.year}
          readOnly={isView}
          onChange={handleChange}
          name="year"
          onBlur={HandleCheckYear}
          className="focus:outline-none focus:border-primary rounded-md border border-border-gray w-full px-5 py-[15px] lssm:text-px14 md:text-px16 font-medium text-black mt-4 mb-1"
        />
        <p className="lssm:text-px12 md:text-px14 text-[#EE0000]">
          {messageYear}
        </p>

        <label
          htmlFor={isView ? "" : "choseFile"}
          className="w-full h-[184px] flex border-dotted border-2 border-primary items-center justify-center flex-col rounded-md mt-8"
        >
          {historyData.imageUrl !== "" ? (
            <img
              src={historyData.imageUrl}
              alt=""
              className="w-full max-h-full object-contain"
            />
          ) : (
            <>
              <img src={`${hostBE}/fe-images/addImage.png`} alt="anh" />
              <p className="font-normal mt-3 text-center text-base">
                {titleUpload}
              </p>
            </>
          )}
          {!isView && (
            <input
              readOnly={isView}
              onChange={changeFile}
              id="choseFile"
              type="file"
              className="hidden"
            />
          )}
        </label>
      </div>
      <div className="md:w-[62%] w-full h-full flex flex-col items-start sc>768:mt-[32px]">
        <label className="text-primary lssm:text-px14 md:text-px20  font-bold">
          {isEnglish ? "Description" : "Mô tả"}
        </label>
        <textarea
          rows={10}
          onChange={handleChangeText}
          readOnly={isView}
          value={
            isEnglish ? historyData.descriptionEn : historyData.descriptionVi
          }
          name={isEnglish ? "descriptionEn" : "descriptionVi"}
          className="focus:outline-none mt-4 rounded-sm w-full py-5 lssm:text-px14 md:text-px16 px-[23px] border border-border-gray focus:border-primary"
        ></textarea>
        <div className="flex justify-end w-full mt-1">
          <p className="lssm:text-px12 md:text-px14 text-right text-text-gray">
            {isEnglish
              ? historyData.descriptionEn?.length ?? 0
              : historyData.descriptionVi?.length ?? 0}
            /1000
          </p>
        </div>
      </div>
    </div>
  );
}

export default History;
