import clsx from "clsx";
import Avatar from "../Avatar";

type Props = {
    url: string
    name: string
    contact: string
    type: "PHONE" | "GMAIL" | "ZALO" | "SKYPE" | "FACEBOOK" | undefined
}

export default function ContactItem({url, name, contact, type}:Props) {
    return (
        <div className={clsx("mt-[24px] rounded-[20px] w-[300px]   px-[25px] py-[15px]", {"bg-[#EA4335]":type==="GMAIL", "bg-[#01ea89]":type==="SKYPE", "bg-[#1ae892]":type==="PHONE", "bg-[#1be44d]":type==="ZALO", "bg-[#00fe61]":type==="FACEBOOK"} )}>
            <div className="flex items-center">
                    <div className="contact-avatar">
                        <Avatar 
                        url={url} size={50} />
                    </div>
                    <div className="flex flex-col w-[70%] justify-between text-white-color ml-[21px]">
                        <p className="text-px18 font-normal">{name}</p>
                        <a target="blank" href={`${type==="GMAIL" ? "mailto:" : type==="PHONE" ? "tel:" : type==="ZALO" ? "https://zalo.me/" : type === "SKYPE" ? "skype:" : ""}${contact}${type==="SKYPE"?"?chat":""}`} className="text-px14 font-light break-words">{contact}</a>
                    </div>
            </div>
        </div>

    )
}