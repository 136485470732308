import clsx from "clsx"
import { useTranslation } from "react-i18next";
import { CgArrowLongRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import useInView from "../../hooks/useInView";
import { hostBE } from "../../types/host";
import Button from "../Button"
import { getProductActiveHome } from "../../reducers/productPublic";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";

export default function TopicProductV2() {
  const translate = useAppSelector((state) => state.translateSlice);
    const {ref, isInView} = useInView();
    const navigate = useNavigate();
    const [t] = useTranslation();
    const dispatch = useAppDispatch();
    const products = useAppSelector(
      (state) => state.productPublic.productActiveHome
    );
    // console.log(products);
    
    useEffect(() => {
      dispatch(getProductActiveHome());
      // }
    }, []);

    return (
      <div className="2xl:py-[93px] h-full lssm:px-[24px] md:px-[80px]  xl:px-[50px]  2xl:px-[162px] relative mt-[100px]" ref={ref} >

          <div className="overlay-bg"style={{
            backgroundImage: `url(${hostBE}/fe-images/banner-product-feature.png)`, 
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}></div>
          <div className="grid lssm:grid-cols-1 xl:grid-cols-3 gap-x-[165px] h-full">
            <div className="flex items-end h-full py-[24px] 2xl:py-[0]">
                <div>
                    <h2 className={clsx("uppercase w-1920:whitespace-nowrap sc991:w-full sc<992:w-[435px] sc991:mb-[45px] lssm:text-[20px] md:text-[32px] xl:text-[48px] sc text-[#11213F] font-semibold sc991:text-center sc991:text-text-primary sc991:font-bold", {"animate__animated animate__fadeInLeft":isInView})} > {t("home.topic.topic6")}</h2>
                    <div className="w-full sc991:hidden h-[4px] mt-[10px] mb-[45px] rounded-md bg-primary"></div>
                    <p className={clsx("lssm:text-justify lg:text-left text-px18", {"animate__animated animate__fadeInUp":isInView})}>
                    {t("home.description_top2")}
                    </p>
                    <div className="sc991:flex sc991:items-center sc991:justify-center">

                    <Button onClick={()=>navigate("/sanpham")} color="primary" className="h-[50px] w-[130px] mt-[35px] rounded-[5px]">
                      {t("button.see_more")}
                    </Button>
                    </div>

                </div>
            </div>

            <div className="lssm:col-span-1 lssm:grid-cols-1 py-[24px] 2xl:py-[0]  grid lg:grid-cols-3 lg:col-span-2 gap-[22px]">
              {
                products.slice(0, 6).map((p, i) => {
                  return <div className={clsx("group  cursor-pointer relative", {"animate__animated animate__fadeInDown":isInView})} key={i} onClick={() => navigate(`/chitietsanpham/${p.id}`)}>
                    <img src={p.avatarUrl} alt="" className="w-full  lssm:min-h-[250px] h-full object-cover rounded-[10px]" />
                    <div className="group-hover:block hidden  note_title-topic lssm:h-[50px] md:h-[140px]">
                        <span className="absolute w-full px-2 text-center lssm:bottom-[15px] md:bottom-[37px] left-[50%] translate-x-[-50%]   lssm:text-[10px] lsm-380:text-px14 xl:text-[18px] font-semibold text-white-color">{!translate.isEnglish ? p.titleEn : p.titleVi}</span>
                    </div>    
                </div>
                })
              }
              
              

            </div>
          </div>
      </div>
  )
}