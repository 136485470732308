import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next";
import HistoryMobile from "../../components/About/HistoryMobile"
import HistoryWeb from "../../components/About/HistoryWeb"
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getHistory, getHistoryPublic } from "../../reducers/history";
import { hostBE } from "../../types/host";


const LIMIT = 100;

export default function History() {

    const history = useAppSelector(state => state.historySlice.historyPublic);
    const dispatch = useAppDispatch();
    const [t] = useTranslation();


    useEffect(()=> {
    
        dispatch(getHistoryPublic())
    }, [])


    return <div className="relative h-auto mb-[100px]">
         <h2 className="lssm:text-px20 md:text-[40px] xl:text-[48px] text-text-title text-center font-bold my-[60px] sc>768:my-[40px]">{t("about.history.title")}</h2>
          <div className="lssm:hidden md:block">
                <HistoryWeb historyData={history}  />
          </div>

          <div className="lssm:block md:hidden">
                <HistoryMobile historyData={history} />
          </div>


         <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[-1]">
                <img src={`${hostBE}/fe-images/contac-world.png`} alt="" />
            </div>

         </div>
}