import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/modules/grid";
import "swiper/css/navigation";
import { Autoplay, Navigation, Grid } from "swiper";
import React, { useMemo, useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/hook";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { hostBE } from "../../types/host";
import useInView from "../../hooks/useInView";
import clsx from "clsx";

function imageExists(image_url: string){

  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
  http.send();

  return http.status != 404;

}

export default function   SliderCredentials() {

  const [credentials, setCredentials] = useState<string[]>([]);
  const bottomInView = useInView();

  useEffect(() => {
    let num = 1;
    setCredentials([]);
    while(true) {
      let urlCredential =  `/fe-images/certificate${num}.png`
      if(imageExists(urlCredential)) {
      
        setCredentials((pre) => {
          pre.push(urlCredential);
          return pre;
        })
        num++
      } else {
        break;
      }
    }
    // console.log(credentials);
    
  
  }, [])

  const width = useMemo(() => {
    return window.innerWidth;
  }, [window.innerWidth]);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <div className="flex-1 flex items-center  relative w-full">
      <Swiper
        slidesPerView={
          width >= 1850
            ? 4
            : width >= 1224
            ? 4
            : width >= 992
            ? 3
            : width >= 678
            ? 2
            : 1
        }
        spaceBetween={30}
        // slidesPerGroup={1}
        loop={false}
        loopFillGroupWithBlank={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          // Both prevEl & nextEl are null at render so this does not work
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSwiper={(swiper: any) => {
          // Delay execution for the refs to be defined
          setTimeout(() => {
            // Override prevEl & nextEl now that refs are defined
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        modules={[Navigation, Autoplay]}
        className="slider-company-home"
      >
        {
          credentials.map((cre, i) => {
            return <SwiperSlide className="" key={i}>
              <div key={i} className="h-full w-full rounded-md flex justify-center items-center bg-white" style={{height: "100%"}}>
                <img
                  className={clsx("w-full  rounded-md", {
                    "animate__animated animate__pulse": bottomInView.isInView,
                  })}
                  src={`${hostBE}${cre}`}
                  alt=""
                />
              </div>
            </SwiperSlide> 
          }) 
        }
      </Swiper>

      <div
        ref={navigationPrevRef}
        className="absolute top-[50%] lssm:left-[-24px] md:left-[-50px] lg:left-[-60px] cursor-pointer translate-y-[-50%] text-text-primary sc>768:text-white-color sc>768:text-[24px] md:text-[48px]"
      >
        <MdOutlineArrowBackIosNew />
      </div>
      <div
        ref={navigationNextRef}
        className="absolute top-[50%] lssm:right-[-24px] md:right-[-50px] lg:right-[-60px] cursor-pointer translate-y-[-50%] text-text-primary sc>768:text-white-color sc>768:text-[24px] md:text-[48px]"
      >
        <MdOutlineArrowForwardIos />
      </div>
    </div>
  );
}
