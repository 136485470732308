import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { login } from "../reducers/auth";
import { LoginPayload } from "../types";
import { hostBE } from "../types/host";

export default function Login() {
    const [t] = useTranslation();
    const auth = useAppSelector(state => state.authSlice)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(()=> {    
        if(auth.isLogin) {
            navigate("/quanly/thongtintaikhoan");
        }
    }, [auth.isLogin])


    const [data, setData] = useState<LoginPayload>({
        username: "",
        password: "",
        rememberMe: false
    })

    const submitForm = (event:FormEvent) => {
        event.preventDefault();

        dispatch(login(data))
    }

    const handleData = (event:ChangeEvent<HTMLInputElement>)=> {
        setData({
            ...data,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    return (<div className="h-[100vh] grid lssm:grid-cols-1 lg:grid-cols-2 overflow-hidden">
                <div className="lssm:px-[24px] sm:px-[50px] lg:px-[80px] xl:px-[120px] w-1920:px-[185px] flex items-center">
                        <div className="w-full bg-white-color">
                            <h2 className="text-[36px] font-medium lssm:text-center lg:text-left">{t('login.title')}</h2>
                            <p className="text-[#5F5F5F] lssm:text-center lg:text-left">{t('login.sub_title')}</p>


                            <form className="w-full lssm:mt-[82px] lg:mt-[118px]" onSubmit={submitForm}>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="username" className="mb-[24px]">{t('login.form.name')}</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    required
                                    onChange={handleData}
                                    className="rounded-[10px] border-solid w-full  focus:border-transparent focus:outline-none focus:ring-2  border-2  h-[60px] border-gray-300 p-2"
                                 />
                                </div>

                                <div className="flex flex-col w-full mt-[50px]">
                                    <label htmlFor="password" className="mb-[24px]">{t('login.form.password')}</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    required
                                    onChange={handleData}

                                    className="rounded-[10px] border-solid w-full  focus:border-transparent focus:outline-none focus:ring-2  border-2 h-[60px] border-gray-300 p-2"
                                 />
                                </div>
                                <small className="text-[#F93131] text-px16 font-normal mt-[16px] block">{auth.error}</small>

                                <div className="break-point-login lg:hidden"></div>

                                <Button type="submit" color="primary" className="w-[100%] text-px16 font-medium h-[60px] mt-[68px]">{t('login.title')}</Button>
                            </form>
                        </div>
                </div>
                <div className="h-full w-full bg-cover bg-bottom bg-no-repeat lssm:hidden lg:block" 
                style={{
                    backgroundImage: `url(${hostBE}/fe-images/topic1.png)`
                }}
                >
                   
                </div>
            
             </div>)
}