import { ChangeEvent, LegacyRef } from "react"
import { hostBE } from "../../types/host"

type Props = {
    id: string
    refChild: LegacyRef<HTMLInputElement>
    checked?: boolean
    onChange?: (event:ChangeEvent<HTMLInputElement>)=> void
}

export default function Checkbox ({id, refChild, checked, onChange}:Props) {

    return (
        <label htmlFor={id} className="checkbox-custom">
        <input ref={refChild} type="checkbox" defaultChecked={checked ? checked : false} onChange={onChange} id={id} hidden />
        <span>
            <img className="w-[11px] h-2" src={`${hostBE}/fe-images/tich.png`} alt="tick" />
        </span>
    </label>
    )
}