import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import useInView from "../../hooks/useInView";
import Button from "../Button";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/hook";
import { hostBE } from "../../types/host";

export default function TopicItem() {
  const { ref, isInView } = useInView();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const translate = useAppSelector((state) => state.translateSlice);
  const refFrame = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      create_iframe();
    }
  }, [refFrame.current]);

  function create_iframe() {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      refFrame.current?.classList.add("ios");
    }
  }

  return (
    <div
      className="lssm:mt-[30px] md:mt-[100px] lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] "
      ref={ref}
    >
      <div className="xl:my-[89px] my-6 pr-0">
        <div
          className={clsx(
            "border-l-[25px] pl-[12px] border-solid border-text-primary text-text-title font-bold uppercase 2xl:leading-[35px] m992:mb-10 mb-1 lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px] sc>768:border-l-[5px]",
            { "animate__animated animate__fadeInRight": isInView }
          )}
        >
          {t("home.topic.topic1")}
        </div>
        <div
          className={clsx("flex justify-between items-end text", {
            "animate__animated animate__fadeInRight": isInView,
          })}
        >
          {/* <div
            className={clsx(
              "lg:w-[59%] sm-390:w-[90%] w-full text-right md:pl-[12px] uppercase border-solid text-text-title font-bold 2xl:leading-[35px] lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px]",
              { "animate__animated animate__fadeInRight": isInView }
            )}
          >
            {translate.isEnglish
              ? t("home.topic.topic1").slice(-24)
              : t("home.topic.topic1").slice(-15)}
          </div> */}
          {/* <div className="2xl:w-[39%] lg:w-[40%] sm-390:block hidden w-[8%]  h-[2px] bg-primary"></div> */}
        </div>
      </div>

      <div>
        <div
          className={clsx(
            " sc<992:flex justify-between sc991:flex-col  mt-[30px] pl-[-20px]"
          )}
        >
           <div className={clsx("w-[40%] sc<992:mr-[60px]  sc991:w-full sc991:mt-[24px]")}>
            {/* <div
              className={clsx(
                "lssm:text-[20px] lg:text-[28px] xl:text-[32px] uppercase font-semibold sc991:font-bold mt-[12px] text-text-primary sc<992:w-[70%] sc991:text-center",
                { "animate__animated animate__fadeInLeft": isInView }
              )}
              dangerouslySetInnerHTML={{ __html: t("home.sub_title") }}
            ></div> */}
            <div
              className={clsx(
                "lssm:text-px14 md:text-px16 sc<992:mb-[32px] sc991:mb-3 text-justify",
                { "animate__animated animate__flash": isInView }
              )}
              dangerouslySetInnerHTML={{ __html: t("home.description_sub") }}
            ></div>
            <div className="sc>768:flex sc>768:justify-center">
              <Button
                onClick={() => navigate("/gioi-thieu")}
                color="primary"
                className="sc>768:text-px14 sc<992:mb-[72px] sc991:mx-auto sc991:mb-6 h-[50px] rounded-[5px] w-[130px] shadow-md"
              >
                <span className="flex items-center text-inherit font-medium">
                  {t("button.see_more")}
                </span>
              </Button>{" "}
            </div>
            <div></div>
          </div>
          <div
            ref={refFrame}
            className={clsx(
              "sc<992:w-[60%]  sc991:w-full sc991:order-1 relative",
              {
                "animate__animated animate__fadeInRight": isInView,
              }
            )}
          >
            <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" allowFullScreen={true}  className='sc991:w-full lssm:h-[300px]  md:h-[450px] lg:h-[610px] xl:h-[492px] md:w-full object-cover rounded-[10px]'  width="100%" height="315" src={`https://www.youtube.com/embed/uuP8GKj8KkM`}
             title="Anfico" ></iframe>
          
          </div>

         
        </div>
      </div>
    </div>
  );
}
