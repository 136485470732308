import { t } from "i18next";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { hostBE } from "../../types/host";
type Props = {
  onClickButton: (file: any) => void;
  className?: string;
};
function ButtonAddImage({ onClickButton, className }: Props) {
  const { t } = useTranslation();
  const changeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if (file) {
      onClickButton(file[0]);
      event.target.value = "";
    }
  };
  return (
    <div
      className={`btn px-5 py-4 border rounded-md border-border-gray ${className} `}
    >
      <label className="flex items-center cursor-pointer">
        <input
          type="file"
          className="hidden"
          onChange={(event) => changeInput(event)}
        />
        <img src={`${hostBE}/fe-images/addimagebnt.png`} alt="add" className="mr-5" />
        <span className="text-text-red lssm:text-px14 md:text-px20 font-bold">
          {t("button.updoad")}
        </span>
      </label>
    </div>
  );
}

export default ButtonAddImage;
