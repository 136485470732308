type Props = {
    width: number | string;
    height: number | string;
  };
  
  export default function MapPlace({ width, height }: Props) {
    return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3726.718013880151!2d106.0712155!3d20.923661699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135a3bcc56631bd%3A0xc28ed29092b24dac!2sBIONU%20VIETNAM%20COMPANY%20LIMITED!5e0!3m2!1svi!2s!4v1675824454011!5m2!1svi!2s"
        width={width}
        height={height}
      ></iframe>
    );
  }
  