import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import History from "../../components/ManagerComponent/History";
import TitlePage from "../../components/ManagerComponent/TitlePage";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { HistoryState } from "../../types";
import uploadService from "../../services/uploadImage";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  createHistory,
  getHistory,
  setCurrentPage,
} from "../../reducers/history";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";
import HistoryEdit from "../../components/ManagerComponent/HistoryEdit";
import { hostBE } from "../../types/host";
import Button from "../../components/Button";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const LIMIT = 2;

function HistoryManager() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [addFormVn, setAddFormVn] = useState<boolean>(false);
  const [fileYear, setFileYear] = useState<File | null>(null);
  const historiesData = useAppSelector((state) => state.historySlice);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isSubmit, setSubmit] = useState(false);
  const refIterView = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollBy(0, 200);
  }, []);

  const [historyData, setHistoryData] = useState<HistoryState>({
    year: "",
    descriptionVi: "",
    descriptionEn: "",
    imageUrl: "",
    imagePath: "",
  });

  const handleFile = (file: File) => {
    setFileYear(file);
    const url = URL.createObjectURL(file);
    setHistoryData({
      ...historyData,
      imageUrl: url,
    });
  };

  const total = useMemo(() => {
    return Math.ceil(historiesData.total / LIMIT);
  }, [historiesData.total]);

  useEffect(() => {
    if (historiesData.histories.length === 0) {
      dispatch(
        getHistory({
          page: 1,
          limit: LIMIT,
        })
      );
      dispatch(setCurrentPage(1));
    }
  }, []);

  const handleData = (data: HistoryState) => {
    setHistoryData({
      ...data,
    });
  };

  const subMitForm = async () => {
    if (isSubmit) {
      let resFile;
      if (fileYear) {
        const formData = new FormData();
        formData.append("file", fileYear);
        resFile = await uploadService.upload(formData);
      }

      dispatch(
        createHistory({
          ...historyData,
          imageUrl: resFile?.list[0].image_url ?? "",
          imagePath: resFile?.list[0].image_path ?? "",
        })
      );

      setHistoryData({
        year: "",
        descriptionVi: "",
        descriptionEn: "",
        imageUrl: "",
        imagePath: "",
      });

      setFileYear(null);
    }
  };

  const fechData = () => {
    const page = historiesData.currentPage;
    if (page <= total) {
      dispatch(
        getHistory({
          page: page + 1,
          limit: LIMIT,
        })
      );

      dispatch(setCurrentPage(page + 1));
    }
  };

  const resetForm = () => {
    setFileYear(null);
    setHistoryData({
      year: "",
      descriptionVi: "",
      descriptionEn: "",
      imageUrl: "",
      imagePath: "",
    });
    setAddFormVn(false);
  };

  return (
    <div className="w-full">
      <div className="mb-10" ref={refIterView}>
        <InfiniteScroll
          hasMore={true}
          loader={
            historiesData.currentPage <= total ? <h4>Loading...</h4> : <></>
          }
          next={fechData}
          dataLength={historiesData.histories.length}
        >
          <Swiper
            allowTouchMove={false}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(swiper: any) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            modules={[Navigation]}
          >
            <SwiperSlide>
              <TitlePage content="LỊCH SỬ HÌNH THÀNH VÀ PHÁT TRIỂN" />
              <div
                onClick={() => {
                  setAddFormVn(true);
                }}
                className="w-full lssm:h-[50px] bg-[#1a73e80d] md:h-[100px] flex items-center justify-center cursor-pointer border rounded-md border-primary mb-20"
              >
                <img src={`${hostBE}/fe-images/add.png`} alt="add" className="mr-3" />
                <p className="lssm:text-px13 md:text-px16 font-medium text-primary">
                  Thêm năm lịch sử hình thành và phát triển
                </p>
              </div>

              <div className="flex justify-end mt-[120px]">
                <Button
                  color="empty"
                  onClick={resetForm}
                  className="mr-[10px] px-6 border rounded-md border-text-lightred text-text-lightred font-medium lssm:text-px14  md:text-px16 text-center"
                >
                  Hủy
                </Button>
                <button
                  ref={navigationNextRef}
                  className="py-[14px] px-6 font-medium border rounded-md bg-primary text-white lssm:text-px14  md:text-px16text-center"
                >
                  Tiếp theo
                </button>
              </div>

              {addFormVn && (
                <History
                  lable="Năm"
                  description="form.discription"
                  titleUpload="Tải ảnh lên"
                  historyData={historyData}
                  setHistoryData={handleData}
                  setSubmit={setSubmit}
                  isView={false}
                  isEnglish={false}
                  setFileYear={handleFile}
                />
              )}

              {historiesData.histories.map((item, index) => {
                return (
                  <HistoryEdit
                    key={index}
                    lable="Năm"
                    description="Mô tả"
                    titleUpload="Tải ảnh lên"
                    historyData={item}
                    isEdit={true}
                    isView={false}
                    isEnglish={false}
                  />
                );
              })}
            </SwiperSlide>

            <SwiperSlide>
              <TitlePage content="HISTORY OF FOUNDATION AND DEVELOPMENT" />
              <div
                onClick={() => {
                  setAddFormVn(true);
                }}
                className="w-full lssm:h-[50px] bg-[#1a73e80d] md:h-[100px] flex items-center justify-center cursor-pointer border rounded-md border-primary mb-20"
              >
                <img src={`${hostBE}/fe-images/add.png`} alt="add" className="mr-3" />
                <p className="text-base font-medium text-primary">
                  Add company's history
                </p>
              </div>
              <div className="flex justify-end mt-[120px]">
                <button
                  ref={navigationPrevRef}
                  className="py-[13px] mr-[10px] px-6 border rounded-md border-text-lightred text-text-lightred font-medium text-px16 text-center"
                >
                  Go back
                </button>

                <button
                  onClick={subMitForm}
                  className={clsx(
                    "py-[13px] px-6 font-medium border rounded-md bg-primary text-white text-px16 text-center",
                    { "opacity-60": !isSubmit }
                  )}
                >
                  Save setting
                </button>
              </div>
              {addFormVn && (
                <History
                  lable="Year"
                  description="Description"
                  titleUpload="Tải ảnh lên"
                  historyData={historyData}
                  setHistoryData={handleData}
                  setSubmit={setSubmit}
                  isView={false}
                  isEnglish={true}
                />
              )}

              {historiesData.histories.map((item, index) => {
                return (
                  <HistoryEdit
                    key={item.id}
                    lable="Year"
                    description="Description"
                    titleUpload="Tải ảnh lên"
                    historyData={item}
                    isView={false}
                    isEdit={true}
                    isEnglish={true}
                  />
                );
              })}
            </SwiperSlide>
          </Swiper>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default HistoryManager;
