import clsx from "clsx";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DialogConfirm from "../../components/DialogConfirm";
import Pagination from "../../components/Pagination";
import ModalResponse from "../../containers/Dashboard/Advise/ModalResponse";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { deleteContactReduce, getContact, searchContact, setCurrentPage } from "../../reducers/contact";
import { showModal } from "../../reducers/modal";
import contactService from "../../services/contact";
import { hostBE } from "../../types/host";

const LIMIT = 10;

export default function Advise() {

    const dispatch = useAppDispatch();

    const contactData = useAppSelector(state => state.contactSlice)
    const [value, setValue] = useState<string>();
    const [searchParam, setSearchParam] = useSearchParams();
    const [t] = useTranslation();
    const {isEnglish} = useAppSelector(state => state.translateSlice)


    useEffect(()=> {
        if(value === undefined || value.trim() === "") {
            
            dispatch(getContact({page: contactData.currentPage, limit: LIMIT}))

        }else {
            dispatch(searchContact({keyword: value, option: {page: contactData.currentPage, limit: LIMIT}}));
        }
    }, [contactData.currentPage])

    useEffect(() => {
        if(value !== undefined) {
            dispatch(searchContact({keyword: value, option: {page: 1, limit: LIMIT}}));
            setSearchParam({page: "1"});
        }
    }, [value])


    const showModalResponse = (id:string) => {
        contactService.getContactById(id).then((data)=> {
            dispatch(showModal(<ModalResponse contact={data} />))

        })
    }

    const showDialogConfirm = (id:string) => {
        dispatch(showModal(<DialogConfirm message="Bạn có chắc chắn muốn xóa yêu cầu này không ?" onClick={()=>deleteContact(id)} />))
    }

    const deleteContact = (id:string) => {
        dispatch(deleteContactReduce(parseInt(id)));
    }

    const total = useMemo(()=> {
        return Math.ceil(contactData.total/LIMIT);
    }, [contactData.total])

    const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
        setValue(event.currentTarget.value);
    }

    return (
        <div>
             <h2 className="text-center text-text-primary lssm:text-px20 md:text-[48px] font-bold mt-[74px] mb-[48px] uppercase">{t("dashboard.request.title")}</h2>

             <div className="w-full h-[60px] border border-solid border-border-color focus-within:border-primary flex items-center rounded-[5px] overflow-hidden">
                <div className="h-full px-[24px] flex items-center border-r border-solid border-border-color">
                    <img src={`${hostBE}/fe-images/icon_search.png`} alt="" />
                </div>
                <input value={value ?? ""} onChange={handleChange} className="flex-1 h-full px-2  outline-none border-none" placeholder={isEnglish ? "Nhập tìm kiếm..." : "Search..."} />
                
            </div>
            {
            contactData.contactList.length > 0 ? (
                <div>
                <div className="mt-[56px] border-2 border-solid border-border-color rounded-[12px] overflow-hidden mb-[60px]">
                    <table className="dashboard-table ">
                        <thead>
                        <tr>
                    
                            <td></td>
                            <td className="sc>768:hidden">{t("dashboard.request.people")}</td>
                            <td className="sc>768:hidden">{t("dashboard.request.company")}</td>
                            <td>{t("dashboard.request.title_table")}</td>
                            <td>{t("dashboard.request.status")}</td>
                        </tr>

                        </thead>
                        <tbody>
                        {
                            contactData.contactList.map((contact)=> {
                                return (
                            <tr key={contact.id} className="cursor-pointer" >
                                    <td>
                                        <div className="flex justify-center items-center relative z-[2]">
                                        <span className="cursor-pointer" onClick={()=> showDialogConfirm(contact.id+"")}><img src={`${hostBE}/fe-images/delete_icon.png`} alt="" /></span>
                                        </div>
                                    </td>
                                    <td className="sc>768:hidden" onClick={()=> showModalResponse(contact.id+"")}>{contact.fullname}</td>
                                    <td className="sc>768:hidden" onClick={()=> showModalResponse(contact.id+"")}>{contact.companyName}</td>
                                    <td onClick={()=> showModalResponse(contact.id+"")}>{contact.note}</td>
                                    <td onClick={()=> showModalResponse(contact.id+"")}>
                                        <span className={clsx({"text-[#5CD931]":contact.status, "text-[#F14040]":!contact.status})}>{contact.status ? (isEnglish ? "Đã phản hồi" : "Answered") : (isEnglish ? "Chưa phản hồi" : "Unanswered")}</span>
                                    </td>
                                    
                            </tr> 
                                )
                            })
                        }
                                

                        </tbody>
                    </table>

                </div>
                    <Pagination currenPage={contactData.currentPage} setCurrentPage={setCurrentPage} total={total} />

                </div>


            ):<div className="text-center mt-[60px]">Không có dữ liệu</div>
 
            }

        </div>
    )
}