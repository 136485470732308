import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../configs/redux/store";
import { hideModal } from "../reducers/modal";
import { pushPopup } from "../reducers/popupSlice";
import { getProductPublic, getProductPublicFail, getProductPublicSuccess, getProductActiveHome, getProductActiveHomeSuccess } from "../reducers/productPublic";
import { TranslateState } from "../reducers/translate";
import ProductPublicService from "../services/productPublicService";
import producService from "../services/products";
import { categoryType, ProducPublicType, ProductItems, productPublicType, resultProductPublic } from "../typeProps/Productype";
import { ResponsiveData } from "../types";

function* getProuductPublicSaga ({payload}: PayloadAction<productPublicType>){
    const translate:TranslateState  = yield select((state:RootState) => state.translateSlice);
    const {isEnglish} = translate;
    try {
        const res :resultProductPublic = yield call(ProductPublicService.getProductPublic,payload);
        yield put(getProductPublicSuccess(res))
    } catch (error) {
        yield put(getProductPublicFail(isEnglish ? "Không tìm thấy sản phẩm" : "No products found"))
    }
}

function* getProductActiveHomeSaga() {
    try {
        const res:ResponsiveData<ProducPublicType> = yield call(producService.getProductActiveHome)
        yield put(getProductActiveHomeSuccess(res.list));
    }catch(error){

    }
}

export default function* prouductPublicSagaMid() {
    yield takeLatest(getProductPublic.type, getProuductPublicSaga);
    yield takeLatest(getProductActiveHome.type, getProductActiveHomeSaga);
  }
  