import clsx from "clsx";
import Cookies from "js-cookie";
import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import { logout } from "../reducers/auth";
import { setTranslate } from "../reducers/translate";
import { logoutUser } from "../reducers/user";
import { navList } from "../Router/NavList";
import { hostBE } from "../types/host";
import MenuRows from "./MenuRows";

type Props = {
  onClose: () => void;
};
function HeaderManager({ onClose }: Props) {
  const iconRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useAppSelector((state) => state.userSlice);
  const [search, setSearch] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translate = useAppSelector((state) => state.translateSlice);

  const handleSearch = () => {
    navigate("/search");
  };

  const setImgageCountry = (value: string) => {
    if (value === "vi") {
      i18n.changeLanguage("vi");
      dispatch(setTranslate("vi"));
    } else {
      i18n.changeLanguage("en");
      dispatch(setTranslate("en"));
    }
  };

  const logoutMethod = () => {
    dispatch(logout());
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <div className="header fixed top-0 bg-white z-[20] border-b border-b-border-box w-full lg:px-[30px] md:px-[90px] lssm:px-[10px] sm:px-[30px] py-4 flex items-center justify-between">
      <div ref={iconRef} className="flex items-center h-30px">
        <AiOutlineMenu
          onClick={() => {
            onClose();
          }}
          className="w-6 m992:h-6 h-5 lg:mr-[30px] cursor-pointer text-primary"
        />
        <Link to="/" className="hidden lg:block h-[30px]">
          <img
            src={`${hostBE}/fe-images/logo.png`}
            alt="ảnh"
            className="cursor-pointer h-full"
          />
        </Link>
      </div>
      <div className="lg:hidden block h-[30px]">
        <img
          onClick={() => {
            navigate("/");
          }}
          src={`${hostBE}/fe-images/logo.png`}
          alt="ảnh"
          className="cursor-pointer h-[30px]"
        />
      </div>

      <div className="flex items-center justify-end w-auto relative">
        {/* menu */}
        <div className="mr-[50px] 2xl:flex hidden  items-center justify-center">
          <MenuRows />
        </div>

        {/* thay đổi ngôn ngữ */}
        <BsSearch
          tabIndex={navList.length + 2}
          onClick={handleSearch}
          className="focus:outline-none text-2xl text-primary hidden lg:block min-h-[24px] xl:block mr-[30px] cursor-pointer"
        />

        <div className="relative lg:mr-[30px] language_image tootip flex items-center">
          <label
            htmlFor="translate"
            className={clsx(
              " w-[30px] mr-[12px] rounded-[2px] overflow-hidden h-[20px] z-[99999] text-center border-solid border-primary flex justify-center items-center",
              { border: translate.isEnglish }
            )}
          >
            <img
              src={`${hostBE}/fe-images/vietnamln.png`}
              onClick={() => setImgageCountry("vi")}
              alt="vi"
              className=" cursor-pointer w-full h-full"
            />
          </label>
          <label
            htmlFor="translate"
            className={clsx(
              "w-[30px] h-[20px] rounded-[2px] overflow-hidden  z-[99999] text-center border-solid border-primary flex justify-center items-center",
              { border: !translate.isEnglish }
            )}
          >
            <img
              onClick={() => setImgageCountry("en")}
              src={`${hostBE}/fe-images/enlng.png`}
              alt="englng"
              className=" cursor-pointer w-full h-full"
            />
          </label>
        </div>
        <div className="hidden lg:block login-hover-root rounded-[50%] w-10 h-10 border cursor-pointer relative">
          <img
            className="rounded-[50%] w-full h-full object-cover"
            src={currentUser?.avatarUrl ?? `${hostBE}/fe-images/logotitle.png`}
            alt="avatar"
          />
          {currentUser && (
            <div className="login-hover bg-white hidden absolute top-[100%] right-0 w-[350px] h-auto border border-solid border-border-box rounded-md py-3 ">
              <div className="border-b-2 border-solid p-3 hover:bg-hover_header">
                <Link to="/quanly/thongtintaikhoan" className="py-3 block ">
                  <p className="text-xl font-bold py-1">
                    {currentUser.fullname}
                  </p>
                  <p className="text-base font-light"> {currentUser.email}</p>
                </Link>
              </div>
              <div className="p-3 font-medium text-xl hover:bg-hover_header">
                {/* <Link to="/quanly/password" className="py-5 block font-medium">
                  {t("header.menu.menuManager.changePassword")}
                </Link> */}
                <div onClick={logoutMethod} className="cursor-pointer text-xl ">
                  {t("header.menu.menuManager.logout")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaderManager;
