import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SliderCredentials from "../../components/Home/SliderCredentials";
import SliderProject from "../../components/Home/SliderProject";
import TopicItem from "../../components/Home/Topic";
import TopicProductV2 from "../../components/Home/TopicProductV2";
import useInView from "../../hooks/useInView";
import { hostBE } from "../../types/host";

export default function Topic() {
  const [t] = useTranslation();
  const bottomInView = useInView();

  
  

  return (
    <div>
      <div className="sc<992:my-[100px] sc991:my-[30px]">
        <TopicItem />
        <div>
          <TopicProductV2 />
          {/* <div className="lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] lssm:mt-[30px]  md:mt-[78px] ">
            <h2 className="text-[#11213F]  sc991:text-text-primary lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px]  sc991:text-center font-bold lssm:mb-[30px]  md:mb-[40px] pl-[20px] border-l-[25px] border-solid uppercase border-primary sc991:border-none block leading-[35px]">
              {t("home.topic.topic2")}
            </h2>
          </div>
          <div className="lssm:h-auto bg-product relative sc<992:h-[690px]">
            <TopicProduct />
          </div> */}
        </div>

        {/* <div className="lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] lssm:mt-[30px]  md:mt-[78px]  ">
          <h2 className="text-[#11213F]  sc991:text-text-primary lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px]  sc991:text-center font-bold   mb-[40px] pl-[20px] border-l-[25px] border-solid uppercase border-primary sc991:border-none block leading-[35px]">
            {t("home.topic.topic3")}
          </h2>
          <SliderProject />
        </div> */}
      </div>

      {/* certificate */}
      {/* <div className=" lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] sc>768:py-[30px]  mb-[32px] md:mb-[49px] ">
        <div className="lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px] font-bold uppercase border-l-[25px] sc>768:mb-[30px] sc>768:text-white-color text-text-title  sc>768:text-center sc>768:border-none border-solid border-primary leading-[35px] pl-[20px]">
        {t("about.general.employe_count")}
        </div>
      </div>  */}
      <div
        className="lssm:mt-[30px] md:mt-[100px] lssm:px-[24px] md:px-[80px]  xl:px-[50px]  w-1920:px-[162px] "
      >
        <div className="xl:mt-[89px] pr-0">
          <div
            className={clsx(
              "border-l-[25px] pl-[12px] border-solid border-text-primary text-text-title font-bold uppercase 2xl:leading-[35px]  lssm:text-px20 md:text-[32px]  xl:text-[34px] 2xl:text-[48px] sc>768:border-l-[5px]",
              // { "animate__animated animate__fadeInRight": isInView }
            )}
          >
            {t("about.general.employe_count")}
          </div>
        </div>
      </div>
      <div className=" mb-[100px]">
        <div className="lssm:mt-[21px] md:mt-[40px] w-full   rounded-md relative" style={{
          backgroundImage: `url(${hostBE}/fe-images/certificate-bg-v2.png)`, 
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat"
        }}>
            <div className="absolute w-1920:right-[42px] w-1920:top-[29px] sm-390:right-4 right-1 sm:right-[32px] top-[16px] w-1920:h-[180px] 2xl:h-[146px] m992:h-[106px] sm:h-[88px] sm-390:h-[48px] h-[32px]">
              <img
                className={clsx("h-full ")}
                src={`${hostBE}/fe-images/brand-award.png`}
                alt="brand-award"
              />
            </div>

            {/* <h3 className="w-1920:pt-[47px] pt-4 w-1920:ml-[131px] ml-4  2xl:ml-[90px] text-[32px] sc>768:text-px16 sc>768:text-center text-white uppercase font-medium">
              {t("about.general.employe_count")}
            </h3> */}
            <div className="mt-4 w-[80%] m-auto flex gap-4 flex-col m992:flex-row  h-auto items-center justify-evenly pt-[100px]">
              {/* <div className="h-[202px] w-[202px] 2xl:h-[282px] 2xl:w-[282px] w-1920:h-[465px] w-1920:w-[465px] rounded-full flex justify-center items-center bg-white">
                <img
                  className={clsx("h-[90%] lg:h-[85%]  rounded-full", {
                    "animate__animated animate__pulse": bottomInView.isInView,
                  })}
                  src={`${hostBE}/fe-images/certificate2.png`}
                  alt=""
                />
              </div>
              <div className="h-[202px] w-[202px] 2xl:h-[282px] 2xl:w-[282px] w-1920:h-[465px] w-1920:w-[465px] rounded-full flex justify-center items-center bg-white">
                <img
                  className={clsx("w-[95%]  rounded-full", {
                    "animate__animated animate__pulse": bottomInView.isInView,
                  })}
                  src={`${hostBE}/fe-images/certificate3.png`}
                  alt=""
                />
              </div> */}
              
              <SliderCredentials />
            
            </div>
          </div>
      </div>
      
    </div>
  );
}
