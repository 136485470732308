import  clsx  from "clsx";
import { useAppSelector } from "../../hooks/hook";
import useInView from "../../hooks/useInView"
import { HistoryState } from "../../types";
import { hostBE } from "../../types/host";
type Props = {
    his: HistoryState;
    index:number
}
export default function HistoryMobileItem({his, index}:Props) {
    const translate = useAppSelector(state => state.translateSlice);
    const {isInView, ref} = useInView()
    return (
        <div className={clsx("flex items-center", {"pt-[100px]":index!==0,"animate__animated animate__fadeInUp":isInView})} ref={ref}>
            <div className="w-[100px] ml-[-2px] lssm:pr-[15px] sm-480:pr-[24px]">
                 <img className="w-full" src={`${hostBE}/fe-images/history_right.svg`} alt="" />
                </div>
            <div className="flex flex-col justify-center flex-1">
                <div className="flex items-end">
                <h3 className="text-px20   text-text-primary font-bold">{his.year}</h3>
                <img className='w-[95px] rounded-md h-[62px] object-cover ml-[32px]' src={his.imageUrl} alt="" />

                </div>
                   <p className="text-[14px] text-justify mt-[18px]">{translate.isEnglish ? his.descriptionVi : his.descriptionEn}</p>
                    
            </div>         
       </div>    
    )
}