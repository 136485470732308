import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import DeviceCart from "../../components/ManagerComponent/DeviceCart";
import DeviceCartModal from "../../components/ManagerComponent/DeviceCartModal";
import InputSearch from "../../components/ManagerComponent/InputSearch";
import TitlePage from "../../components/ManagerComponent/TitlePage";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { getDevice, searchDevice, setCurrentPage } from "../../reducers/device";
import { showModal } from "../../reducers/modal";
import { hostBE } from "../../types/host";

const LIMIT = 10;

function DeviceManager() {
  const dispatch = useAppDispatch();

  const deviceData = useAppSelector((state) => state.DeviceSlice);
  const [searchValue, setSearchValue] = useState<string>();
  const [searchParam, setSearchParam] = useSearchParams();
  const { t } = useTranslation();
  useEffect(() => {
    if (!searchValue || searchValue?.trim() === "") {
      dispatch(getDevice({ page: deviceData.currentPage, limit: LIMIT }));
    } else {
      dispatch(
        searchDevice({
          keyword: searchValue ?? "",
          option: { page: 1, limit: LIMIT },
        })
      );
    }
  }, [deviceData.currentPage]);

  useEffect(() => {
    if (searchValue !== undefined) {
      dispatch(
        searchDevice({
          keyword: searchValue,
          option: { page: 1, limit: LIMIT },
        })
      );
      setSearchParam({ page: "1" });
      dispatch(setCurrentPage(1));
    }
  }, [searchValue]);

  const handleAddDevice = () => {
    dispatch(showModal(<DeviceCartModal />));
  };

  const total = useMemo(() => {
    return Math.ceil(deviceData.total / LIMIT);
  }, [deviceData.total]);

  return (
    <div className="w-full 2xl:pb-[100px] pb-5">
      <TitlePage content="titleManager.equimenttitle" />
      <div className="w-full flex sm:flex-row flex-col justify-between items-center px-[14px]">
        <div className="2xl:w-3/4 sm:w-[65%] w-full sm:mb-0 mb-5">
          <InputSearch
            ChangeInputFc={(param) => {
              setSearchValue(param);
            }}
          />
        </div>

        <div className="2xl:w-[24%] sm:w-[30%] w-full">
          <Button
            color="primary"
            onClick={() => {
              handleAddDevice();
            }}
            className="w-full xl:py-4 py-[14px] w-1920:px-12 px-3 flex "
            disabled={false}
            type="button"
          >
            <img className="mr-3" src={`${hostBE}/fe-images/add1.png`} alt="add" />
            <p className="xl:text-base text-[13px] font-medium text-white">
              {t("button.equipment-btn")}
            </p>
          </Button>
        </div>
      </div>
      {deviceData.devices.length === 0 ? (
        <p className="text-center mt-[60px]">{t("common.no_data")}</p>
      ) : (
        <div className="w-full">
          <p className="xl:text-px20 text-base font-bold text-primary py-4 text-left 2xl:my-[50px] my-[30px] px-[14px]">
            {t("form.equementlist")}
          </p>
          <div className="w-full flex flex-wrap items-center">
            {deviceData.devices.map((device) => {
              return <DeviceCart key={device.id} device={device} />;
            })}
          </div>
          <div className="flex justify-end">
            <Pagination
              currenPage={deviceData.currentPage}
              setCurrentPage={setCurrentPage}
              total={total}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DeviceManager;
