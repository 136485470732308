import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../hooks/hook"
import { hideModal } from "../../reducers/modal"
import { hostBE } from "../../types/host"
import Button from "../Button"

type Props = {
    message: string
    onClick?: ()=> void
}

export default function DialogConfirm({message, onClick}:Props) {
    const dispatch = useAppDispatch();
    const {isEnglish} = useAppSelector(state => state.translateSlice)
    const [t] = useTranslation();

    return (
        <div className=" max-w-full lssm:w-[370px] sm:w-[500px] md:w-[678px] bg-white sc>768:h-[300px] sc>768:rounded-b-[30px] md:h-[364px] p-[44px] flex flex-col justify-between items-center rounded-[10px]">
                <span><img src={`${hostBE}/fe-images/delete_confirm.png`} alt="" /></span>
                <p className="sc>768:text-px14 text-center md:text-px20 font-bold">{isEnglish ? message : "Are you sure you want to remove ?"}</p>
                <div className="flex w-full md:justify-center sc>768:grid sc>768:grid-cols-2">
                    <Button onClick={()=>dispatch(hideModal())} color="empty" className="sc>768:w-full sc>768:text-text-primary lssm:text-px14  md:text-px16 hover:bg-[#1a73e805]">{t("button.cancel")}</Button>
                    <Button onClick={onClick} color="primary" className="sc>768:w-full !bg-[#D70000] ml-3 lssm:text-px14  md:text-px16 font-bold">{t("button.delete")}</Button>
                </div>
        </div>
    )
}