import clsx from "clsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GrFormDown } from "react-icons/gr";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/hook";
import { navList } from "../Router/NavList";

function MenuRows() {
  const location = useLocation();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.userSlice.currentUser);

  const getPathName = useCallback(
    (listPath: any[] | string) => {
      let isCheck = false;
      if(typeof listPath === "string") {
        if(location.pathname === "/" && listPath === "/") {{
          isCheck = true
        }}else {
          isCheck = listPath === "/" ? false : location.pathname.includes(listPath);

        }
      }else {

        const newArr = listPath.map((i) => i.path);
  
         isCheck = newArr.some((item) => item === location.pathname);
  
      }
      return isCheck;
    },
    [location.pathname]
  );
  return (
    <ul className="flex items-center">
      {navList.map((item, index) => {
        return !item.children ? (
          item.path !== "/quanly/thongtintaikhoan" ? (
            <li key={index} tabIndex={index + 2} className="mx-4 block w-max">
              <NavLink
                to={item.path}
                className={clsx("w-max px-[17px] hover:bg-hover_header rounded-[12px] font-medium py-2 uppercase text-base w-1920:text-[20px] 2xl:text-px16 xl:text-[13px] md:text-px16 ", {"bg-hover_header":getPathName(item.path)}) }
              >
                {t(`${item.name}`)}
              </NavLink>
            </li>
          ) : (
            user && (
              <li key={index} className=" mx-4  w-max">
                <NavLink
                  to={item.path}
                  className={clsx("px-[17px] w-max rounded-[12px] hover:bg-hover_header  font-medium py-2 w-1920:text-[20px] 2xl:text-px16 xl:text-[13px] md:text-px16 text-base uppercase ", {"bg-hover_header":getPathName(item.path)}) }
                >
                  {t(`${item.name}`)}
                </NavLink>
              </li>
            )
          )
        ) : (
          <li tabIndex={index + 2} key={index} className="relative mx-4  nav-link">
            <NavLink
              to={item.path}
              className={clsx(
                "cursor-pointer px-[17px] rounded-[12px] hover:bg-hover_header py-2 uppercase w-max font-medium text-base flex items-center xl:text-[13px] w-1920:text-[20px] 2xl:text-px16 md:text-px16 relative",
                {
                  "bg-hover_header": getPathName(
                    item.children
                  ),
                }
              )}
            >
              <div className="flex items-center">
                {t(`${item.name}`)}{" "}
                <span className="ml-2">
                  <GrFormDown />
                </span>
              </div>
            </NavLink>
            <ul className="absolute top-[100%] left-0 w-[250px] h-auto md:text-px16 bg-white-color py-[30px] rounded-b-[10px] rounded-tr-[10px] xl:text-[13px] w-1920:text-px16 2xl:text-px16 text-text-decoration text-px16 font-medium">
              {item.children.map((crPath, index) => {
                return (
                  <li key={index}>
                    <Link
                      className="pl-[30px] py-[12px] block uppercase"
                      to={crPath.path}
                    >
                      {t(`${crPath.name}`)}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
}

export default MenuRows;
