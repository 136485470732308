import clsx from "clsx";
import { useEffect, useState } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import {ImWarning} from "react-icons/im"

import { useAppDispatch } from "../../hooks/hook";
import { ListPopUpState, popPopUp } from "../../reducers/popupSlice";
import { CgDanger } from "react-icons/cg";
import { hostBE } from "../../types/host";

type Props = {
    message: ListPopUpState
}


export default function PopUp ({message}: Props) {

    const [isHidePopUp, setIsHidePopUp] = useState(false)
    const [second, setSecond] = useState<number>(3000)
    const dispatch = useAppDispatch();

    useEffect(()=> {
        const timeOut = setInterval(()=> {
            const time = second - 100;
            setSecond(time)
            if(time === 0) {
                
                    dispatch(popPopUp(message.id+""))
                    setIsHidePopUp(true)
            }

        },100)

        return ()=> {
            clearInterval(timeOut)
        }
    }, [second, dispatch, message])

   
    return(
        !isHidePopUp ? (
        <div className={clsx("w-[300px] h-[55px] max-w-auto mt-3 bg-white-color rounded-md shadow-lg popup-show", {"popup-hide": second === 100})}>
                <div className={clsx("flex items-center p-[24px] h-full  border-solid border-[1px] rounded-md",
                 )}>
                    <span className="text-[20px]">
                        {
                            message.type === "WARNING" && ( <ImWarning className="text-[#FFEB3B]" />) 
                        }
                        {
                            message.type === "SUCCESS" &&  <img className="h-[30px] object-contain" src={`${hostBE}/fe-images/successCompelete.png`} alt="" />
                        }

                        {
                             message.type === "ERROR" &&  <img className="h-[30px] object-contain" src={`${hostBE}/fe-images/delete_confirm.png`} alt="" />
                        }
                       
                    </span><span className="text-px16 ml-2">{message.message}</span>
                </div>
            </div>

        ): <></>
        )
    
    
}