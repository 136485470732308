type Props = {
  width: number | string;
  height: number | string;
};

export default function Map({ width, height }: Props) {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3723.8440216298854!2d105.9046452!3d21.0389262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135a908c6e6f5ef%3A0xd0ff9f98492c6da4!2sChung%20cu%20Sunrise%20Building%20IIIA!5e0!3m2!1svi!2s!4v1675824559672!5m2!1svi!2s"
      width={width}
      height={height}
    ></iframe>
  );
}

