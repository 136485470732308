import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { Search, useSearchParams } from "react-router-dom";
import Pagination from "../components/Pagination";
import SearchItem from "../components/SearchConponent/SearchItem";
import { useAppDispatch, useAppSelector } from "../hooks/hook";
import {
  getSearch,
  setCurrentPage,
  setSearchClient,
  setStateSearch,
} from "../reducers/SearchAllSlice";
import { debounce } from "lodash";
import { useCallback } from "react";
import _debounce from "lodash/debounce";

function SearchAllPage() {
  const { t } = useTranslation();
  const dispath = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputSearch, setInputSearch] = useState<string>("");
  const { isEnglish } = useAppSelector((state) => state.translateSlice);
  const { loadding, error, currentPage, listSearch, total } = useAppSelector(
    (state) => state.searchAllSlice
  );
  const handleButtonSearch = () => {
    dispath(
      getSearch({
        type: isEnglish ? "vi" : "en",
        param: inputSearch,
        option: {
          page: currentPage - 1,
          limit: 9,
        },
      })
    );
  };

  const ChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputSearch(value);
    if (value != "") {
      const formatKey = value.replace(/ /g, "-");
      setSearchParams({ search: formatKey });
      dispath(setCurrentPage(1));
    } else {
      setSearchParams({ search: "" });
      dispath(setSearchClient());
    }
  };

  const handleSearchKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode;
    const keySearch = event.target.value;
    if (keySearch != "" && keyCode === 13) {
      handleButtonSearch();
    }
  };

  useEffect(() => {
    const keySearch = searchParams.get("search");
    if (keySearch && keySearch != "") {
      const formatKey = keySearch.replace(/-/g, " ");
      setInputSearch(formatKey);
    }
    return () => {};
  }, [searchParams]);

  useEffect(() => {
    const keySearch = searchParams.get("search");
    if (keySearch != "" && keySearch !== null) {
      dispath(
        getSearch({
          type: isEnglish ? "vi" : "en",
          param: keySearch,
          option: {
            page: currentPage - 1,
            limit: 9,
          },
        })
      );
    }

    return () => {
      dispath(setSearchClient());
    };
  }, [currentPage]);

  return (
    <div className="w-full min-h-screen">
      <div className="w-full h-auto 2xl:pt-[200px] m992:mt-[100px] pt-[50px] pb-[50px] md:px-0 px-4 ">
        <div
          style={{ boxShadow: "0px 0px 20px -5px rgb(0 0 0 / 20%)" }}
          className="w-1920:w-[83%] 2xl:w-3/4 lg:w-3/4 md:w-[80%] mx-auto w-full bg-white border rounded-md border-border-gray"
        >
          <div className="  w-full py-[50px] px-10">
            <div className="w-full flex flex-wrap items-center justify-between">
              <div className="m992:w-[82%] md:w-3/4 w-full relative">
                <AiOutlineSearch className="absolute cursor-pointer top-[50%] left-4 md:text-4xl text-2xl translate-y-[-50%] text-text-gray" />
                <input
                  value={inputSearch}
                  onKeyUp={(event) => {
                    handleSearchKeyUp(event);
                  }}
                  onChange={(event) => ChangeInput(event)}
                  type="text"
                  placeholder={isEnglish ? "Nhập tìm kiếm ..." : "Search"}
                  className="w-full border border-solid rounded-md focus:outline-none 2xl:py-4 xl:py-3 py-2 md:px-20 pl-12 pr-8  border-text-primary text-lg text-black"
                />
              </div>
              <div className="m992:w-[15%] md:w-1/5 md:block hidden">
                <button
                  onClick={handleButtonSearch}
                  className="text-white flex items-center justify-center  2xl:text-xl xl:text-lg text-base font-bold xl:py-[13px] py-[11px] bg-primary w-full rounded-md border-none h-full"
                >
                  <AiOutlineSearch className="2xl:text-4xl text-2xl 2xl:mr-3 mr-1" />
                  {t("search.search_button")}
                </button>
              </div>
            </div>
            {/* thông tin tìm kiếm */}
            <div className="w-full mt-5 rounded-md">
              <div>
                <p className="mb-5">
                  {t("search.search_result")} : <b>Lorem ipsum</b>
                  {`   (${total})  ${t("search.result")}`}
                </p>
              </div>
              {listSearch.length > 0 &&
                listSearch.map((items, index) => {
                  return <SearchItem key={index} titleItem={items} />;
                })}
            </div>
          </div>

          {total > 0 && (
            <div className="w-full py-5 mb-10 2xl:pr-8 flex 2xl:justify-end justify-center">
              <Pagination
                total={Math.ceil(total / 9)}
                currenPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchAllPage;
