import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import getDate from "../../common/getDate";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import DialogConfirm from "../../components/DialogConfirm";
import CreateNews from "../../components/ManagerComponent/CreateNews";
import InputSearch from "../../components/ManagerComponent/InputSearch";
import TitlePage from "../../components/ManagerComponent/TitlePage";
import PageLoadMore from "../../components/pageLoadMore";
import Pagination from "../../components/Pagination";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { showModal } from "../../reducers/modal";
import {
  deleteNews,
  getNews,
  searchNews,
  setCurrentPage,
} from "../../reducers/news";
import { hostBE } from "../../types/host";

const LIMIT = 10;

function NewsManager() {
  const [valueSearch, setValueSearch] = useState<string>();

  const [itemsCheck, setItemCheck] = useState<any[]>([]);
  const refCheckboxAll = useRef<HTMLInputElement>(null);
  const refCheckboxs = useRef<HTMLInputElement[] | any[]>([]);
  const [listnew] = useState([1, 2, 3]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const [t] = useTranslation();
  const {isEnglish} = useAppSelector(state => state.translateSlice);

  const newsData = useAppSelector((state) => state.newsSlice);

  const total = useMemo(() => {
    return Math.ceil(newsData.total / LIMIT);
  }, [newsData.total]);

  useEffect(() => {
    if (valueSearch === undefined || valueSearch.trim() === "") {
      dispatch(
        getNews({
          page: newsData.currentPage,
          limit: LIMIT,
        })
      );
    } else {
      dispatch(
        searchNews({
          keyword: valueSearch,
          option: { page: newsData.currentPage, limit: LIMIT },
        })
      );
    }
  }, [newsData.currentPage]);

  useEffect(() => {
    if (valueSearch !== undefined) {
      dispatch(
        searchNews({ keyword: valueSearch, option: { page: 1, limit: LIMIT } })
      );
      setSearchParam({ page: "1" });
    }
  }, [valueSearch]);

  const handleAddNew = () => {
    navigate("create");
  };

  const ViewsNew = (id: any) => {
    navigate(`view/${id}`);
  };

  useEffect(() => {
    if (itemsCheck.length === listnew.length) {
      refCheckboxAll.current!.checked = true;
    } else {
      refCheckboxAll.current!.checked = false;
    }
  }, [itemsCheck]);

  const checkAll = (e: ChangeEvent<HTMLInputElement>) => {
    const isCheckAll = e.target.checked;
    refCheckboxs.current.forEach((item: HTMLInputElement, index) => {
      if (isCheckAll) {
        setItemCheck([...listnew]);
      } else {
        setItemCheck([]);
      }
      if (item) item.checked = isCheckAll;
    });
  };

  const handleCheckItems = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const isChecked = refCheckboxs.current[index].checked;
    if (isChecked) {
      setItemCheck([...itemsCheck, newsData.newsList[index].id]);
    } else {
      const tempArr = itemsCheck.filter((item) => {
        return item !== newsData.newsList[index].id;
      });
      setItemCheck([...tempArr]);
    }
  };

  const handleDelete = () => {
    if (itemsCheck.length > 0) {
      dispatch(
        showModal(
          <DialogConfirm
            message="Bạn có chắc muốn xóa tin này không?"
            onClick={deleteNewsMulti}
          />
        )
      );
    }
  };

  const deleteNewsMulti = () => {
    dispatch(deleteNews(itemsCheck));
  };

  return (
    <div className="w-full">
      <TitlePage content="titleManager.newTitle" />
      <div className="w-full flex justify-between items-center px-[10px] sc>768:flex-wrap">
        <div className="w-auto sc>768:order-1 sc>768:w-[48%] sc>768:mt-[24px]">
          <Button
            color="empty"
            onClick={handleDelete}
            className="w-full h-[50px] xl:h-[60px] rounded-md mr-[18px] border sc>768:w-full"
          >
            <div className="flex items-center justify-center">
              <img
                src={`${hostBE}/fe-images/delete.png`}
                alt="delete"
                className="mr-[10px]"
              />
              <span className="lssm:text-px14 md:text-px16 text-text-red">{t("button.delete")}</span>
            </div>
          </Button>
        </div>
        <div className={`md:flex-1 sc>768:w-full md:mx-[12px]`}>
          <InputSearch
            ChangeInputFc={(param) => {
              setValueSearch(param);
            }}
          />
        </div>

        <div className="w-[24%] sc>768:w-[48%] sc>768:order-3 sc>768:mt-[24px]">
          <Button
            color="primary"
            onClick={() => {
              handleAddNew();
            }}
            className="w-full h-[50px] xl:h-[60px] flex "
            disabled={false}
            type="button"
          >
            <img className="mr-3" src={`${hostBE}/fe-images/add1.png`} alt="add" />
            <p className="lssm:text-px14 md:text-px16 font-medium text-white">{t("dashboard.news.btn_add")}</p>
          </Button>
        </div>
      </div>

      <div className="py-[50px] px-[10px]">
        <table className="table-manager  w-full border rounded-md border-collapse text-center">
          <thead>
            <tr className="w-full font-bold text-primary py-[30px]">
              <td className="w-[5%]">
                <Checkbox
                  onChange={checkAll}
                  refChild={refCheckboxAll}
                  id="news_all"
                />
              </td>
              <td className="w-[5%]">{t("dashboard.news.table.no")}</td>
              <td className="w-[20%]">{t("dashboard.news.table.title")}</td>
              <td className="w-[55%] sc>768:hidden">{t("dashboard.news.table.content")}</td>
              <td className="w-[15%] sc>768:hidden">{t("dashboard.news.table.date")}</td>
              {/* <td className="w-[5%] md:hidden">
               <Checkbox onChange={checkAll} refChild={refCheckboxAll} id="news_all" />
              </td> */}
            </tr>
          </thead>
          <tbody>
            {newsData.newsList.length === 0 && (
              <tr>
                <td colSpan={5}>{t("common.no_data")}</td>
              </tr>
            )}
            {newsData.newsList.map((item, index) => {
              return (
                <tr key={item.id} className="cursor-pointer">
                  <td className="">
                    <Checkbox
                      refChild={(element: any) => {
                        refCheckboxs.current[index] = element;
                      }}
                      id={`news_${item.id}`}
                      onChange={(event) => handleCheckItems(event, index)}
                    />
                  </td>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`view/${item.id}`}>{ isEnglish ?item.titleVi : item.titleEn }</Link>
                  </td>
                  <td
                    onClick={() => ViewsNew(item.id)}
                    className="sc>768:hidden"
                  >
                    { isEnglish ? item.descriptionVi : item.descriptionEn }
                  </td>
                  <td
                    onClick={() => ViewsNew(item.id)}
                    className="sc>768:hidden"
                  >
                    {getDate(item.createdDate + "")}
                  </td>
                  {/* <td className="md:hidden">
                    <Checkbox refChild={(element:any) => {
                        refCheckboxs.current[index] = element;
                      }} id={`news_${item}`} onChange={(event) => handleCheckItems(event, index)} />
                   
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="mt-[100px]">
          <div className="sc>768:hidden">
            <Pagination
              total={total}
              currenPage={newsData.currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <div className="md:hidden flex justify-center">
            {newsData.currentPage < total && (
              <PageLoadMore
                currentPage={newsData.currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsManager;
