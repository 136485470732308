import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/hook";
import { hideModal } from "../../reducers/modal";
import { Device } from "../../types";
import ButtonAddImage from "../Button/ButtonAddImage";
import * as Yup from "yup";
import uploadService from "../../services/uploadImage";
import { createDevice, updateDevice } from "../../reducers/device";
import { hostBE } from "../../types/host";
import resizeFile from "../../common/reSize";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
type Props = {
  device?: Device;
};

function DeviceCartModal({ device }: Props) {
  const dispatch = useAppDispatch();
  const [fileImage, setFileImage] = useState<any>(undefined);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      descriptionEn: "",
      descriptionVi: "",
      imageUrl: "",
    },
    validationSchema: Yup.object({
      descriptionEn: Yup.string().required(t("validate.error.required")),
      descriptionVi: Yup.string().required(t("validate.error.required")),
      imageUrl: Yup.string().required(t("validate.error.required")),
    }),
    onSubmit: async (values) => {
      let urlFile;
      if (fileImage) {
        const formData = new FormData();
        const newFile = await resizeFile(fileImage, {
          width: 356,
          height: 298,
        });
        formData.append("file", newFile);
        urlFile = await uploadService.upload(formData);
      }
      if (device) {
        dispatch(
          updateDevice({
            id: device.id,
            descriptionEn: values.descriptionEn,
            descriptionVi: values.descriptionVi,
            topicImage: {
              imagePath:
                urlFile?.list[0].image_path ?? device.topicImage.imagePath,
              imageUrl:
                urlFile?.list[0].image_url ?? device.topicImage.imageUrl,
            },
          })
        );
      } else {
        dispatch(
          createDevice({
            descriptionEn: values.descriptionEn,
            descriptionVi: values.descriptionVi,
            topicImage: {
              imagePath: urlFile?.list[0].image_path ?? "",
              imageUrl: urlFile?.list[0].image_url ?? "",
            },
          })
        );
      }
    },
  });

  useEffect(() => {
    if (device) {
      formik.setValues({
        descriptionEn: device.descriptionEn,
        descriptionVi: device.descriptionVi,
        imageUrl: device.topicImage?.imageUrl ?? "",
      });
    }
  }, []);

  const handleChoseFile = (file: any) => {
    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    if (file) {
      setFileImage(file);
      toBase64(file).then((result) => {
        formik.setFieldValue("imageUrl", result);
      });
    }
  };

  return (
    <div className="2xl:w-[1160px] xl:w-[1000px] sm-390:w-full px-3 w-full  border border-border-gray rounded-md py-[50px] xl:px-[109px] bg-white">
      <form onSubmit={formik.handleSubmit} className="w-full">
        <p className="xl:text-[32px] text-xl xl:text-black text-primary xl:mb-[50px] mb-7 text-center">
          {device
            ? t(`titleManager.titleEquementEdit`)
            : t(`titleManager.titleEquementAdd`)}
        </p>
        <p className="lssm:text-px14 md:text-px20 font-bold sc>768:mt-[24px] md:mt[37px] text-primary xl:py-4 py-2 text-left">
          {t("form.equementname")}
        </p>
        <input
          className="w-full px-3 py-3 border-border-gray border rounded-md focus:outline-none"
          type="text"
          value={formik.values.descriptionVi}
          onChange={formik.handleChange}
          name="descriptionVi"
        />
        <p className="text-px14 text-text-lightred mt-2">
          {formik.errors.descriptionVi}
        </p>

        <p className="lssm:text-px14 md:text-px20 font-bold sc>768:mt-[24px] md:mt[37px] text-primary xl:py-4 py-2 text-left">
          Device name
        </p>
        <input
          className="w-full px-3 py-3  border-border-gray border rounded-md focus:outline-none"
          type="text"
          value={formik.values.descriptionEn}
          onChange={formik.handleChange}
          name="descriptionEn"
        />
        <p className="text-px14 text-text-lightred mt-2">
          {formik.errors.descriptionEn}
        </p>
        <p className="lssm:text-px14 md:text-px20 font-bold sc>768:mt-[24px] md:mt[37px] text-primary xl:py-4 py-2 text-left">
          {t("form.uploadimage")}
        </p>

        <div className="py-5 px-5 rounded-md border border-border-gray flex flex-wrap items-center ">
          <div className="flex flex-col xl:w-2/4 w-full justify-center">
            <p className="text-sm text-text-medium hidden xl:block mb-3">
              {t("form.uploaddescription")}
            </p>
            <ButtonAddImage
              className="xl:mt-[50px] mb-6 xl:w-fit w-full"
              onClickButton={handleChoseFile}
            />
          </div>

          <div className="flex flex-col  xl:w-2/4 w-full justify-center">
            <p className="text-sm text-text-medium mb-3">
              {t("form.imagepreview")}
            </p>
            <div className="xl:w-[250px] w-full h-[141px] flex items-center justify-center bg-bg-gray rounded-md ">
              {formik.values.imageUrl !== "" ? (
                <img
                  className="w-full h-full rounded-md"
                  src={formik.values.imageUrl}
                  alt="imagedefault"
                />
              ) : (
                <img src={`${hostBE}/fe-images/imagedefault.png`} alt="imagedefault" />
              )}
            </div>
          </div>
        </div>
        <p className="text-px14 text-text-lightred mt-2">
          {formik.errors.imageUrl}
        </p>

        <div className="flex xl:justify-end justify-between mt-[60px]">
          <button
            onClick={() => dispatch(hideModal())}
            type="button"
            className="xl:w-[180px] w-[48%] py-[13px] mr-[10px] px-6 border rounded-md border-text-lightred text-text-lightred md:text-xl text-lg text-center"
          >
            {t("button.button_prev")}
          </button>
          <button
            type="submit"
            className="xl:w-[180px] w-[48%] py-[13px] px-6 border rounded-md bg-primary text-white md:text-xl text-lg text-center"
          >
            {t("button.button_save")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default DeviceCartModal;
