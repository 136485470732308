import { BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import {
  AiOutlineMenu,
  AiOutlineInfoCircle,
  AiOutlineShop,
  AiOutlinePicture,
  AiOutlineIdcard,
  AiOutlineUsergroupAdd,
  AiOutlineHome,
} from "react-icons/ai";
import { CgProfile } from "react-icons/cg";

export const navList = [
  {
    path: "/",
    name: "header.menu.home",
    icon: <AiOutlineHome />,
  },
  {
    path: "/gioi-thieu",
    name: "header.menu.introduce",
    icon: <AiOutlineInfoCircle />,

    children: [
      {
        path: "/gioi-thieu",
        name: "header.menu.children.general_Introduction",

      },
      {
        path: "/gioi-thieu/lich-su",
        name: "header.menu.children.history",
      },
      // {
      //   path: "/gioi-thieu/gia-tri-cot-loi",
      //   name: "header.menu.children.vision_mission_values",
      // },
      // {
      //   path: "/gioi-thieu/he-thong-quan-ly",
      //   name: "header.menu.children.manager_system",
      // },
      // {
      //   path: "/gioi-thieu/trang-thiet-bi",
      //   name: "header.menu.children.equipment",
      // },
    ],
  },
  {
    path: "/sanpham",
    name: "header.menu.product",
    icon: <AiOutlineShop />,

  },
  // {
  //   path: "/du-an",
  //   name: "header.menu.project",
  //   icon: <AiOutlineShop />,

  // },
  {
    path: "/tin-tuc",
    name: "header.menu.news",
    icon: <BsReverseLayoutTextWindowReverse />,

  },
  {
    path: "/lien-he",
    name: "header.menu.contact",
    icon: <AiOutlineIdcard />,
  },
  // {
  //   path: "/tuyen-dung",
  //   name: "header.menu.recruit",
  //   icon: <AiOutlineUsergroupAdd />,

  // },
  // {
  //   path: "/album",
  //   name: "header.menu.photo",
  //   icon: <AiOutlinePicture />,

  // },
  {
    path: "/quanly/thongtintaikhoan",
    name: "header.menu.account",
    icon: <CgProfile />,

  },
];
